export default interface UserMetadata {
  avatarUrl?: string;
  userId?: string;
  acceptedTerms?: string[];
  accounts?: string[];
  supportedCountries?: string[];
  profile?: {
    preferredLanguage?: string;
    preferredTimeFormat?: string;
    timezone?: string;
  };
  authorization?: {
    groups?: string[];
    scopes?: string[];
  };
  deliveryCenterIds?: string[];
  routeId?: string;
  vendors?: Vendor[];
  nodes?: Record<string, string[]>;
  affiliation?: Affiliation;
}

export interface Vendor {
  id?: string;
  country?: string;
  displayName?: string;
  serviceModel?: string;
  governmentId?: string;
  abiVendorId?: string;
  tier?: {
    name?: string;
    app?: string;
  };
  scopes?: string[];
  stores?: Store[];
  isManufacturer?: boolean;
  roles?: string[];
}

export interface Store {
  id: string;
  name: string;
  status: 'ACTIVE' | 'INACTIVE' | 'EXPERIMENTATION';
}

export enum Affiliation {
  ABI = 'ABI',
  GLOBAL_GROWTH = 'GLOBAL GROWTH',
  MANUFACTURER = 'MANUFACTURER',
  SELLER_DTAAS = 'SELLER DTAAS',
  SELLER_3P_PRO = 'SELLER 3P PRO'
}